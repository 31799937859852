<template>
  <div id="app">
      <router-view  />
  </div>
</template>
<script>
export default {
  created() {
    // if (sessionStorage.getItem('instituteObj')) {
    //   this.$store.commit('setInstituteObj', sessionStorage.getItem('instituteObj'));
    //   sessionStorage.removeItem('instituteObj');
    // } else {
    //   this.$store.commit('setInstituteObj', {});
    //   sessionStorage.removeItem('instituteObj');
    // }
    // console.log(this.$route)
  },
  // mounted() {
  //   let self = this;
  //   window.addEventListener('beforeunload', e => {
  //     if (self.$store.state.instituteObj) {
  //       sessionStorage.setItem('instituteObj', self.$store.state.instituteObj);
  //     }
  //   });
  // }
};
</script>
<style lang="scss"></style>
